<template>
  <div class="page-container page-customType">
      <el-form label-position="right"  :model="saveForm" :rules="saveRules" ref="saveForm" label-width="100px">
          <el-form-item label="产品型号" prop="modelName">
              <el-input v-model="saveForm.modelName"></el-input>
          </el-form-item>
      </el-form>
      <div class="operations">
        <div class="operation-btns">
            <div @click="handleAdd">添加一行</div>
        </div>
        <div style="color: red;float:left;padding-top: 4px;">顶级查询结果与当前模板顺序相反，请使用<i style="font-size: 19px;" class="el-icon-upload2"/><i  style="font-size: 19px;" class="el-icon-download"/>合理调整顺序</div>
      </div>
      <el-table
        :data="tableData"
        border
        tooltip-effect="dark"
        style="width: 100%;"
       >
        <el-table-column label="序号"
                type="index"
                width="50">
        </el-table-column>
        <el-table-column
                prop="nameCh"
                align="center"
                label="中文名称"
        >
        </el-table-column>
        <el-table-column
                prop="nameEn"
                align="center"
                label="英文名称"
        >
        </el-table-column>
        <el-table-column
                prop="type"
                align="center"
                label="是否必填"
        >
           <template slot-scope="scope">
               {{ Boolean(scope.row.mandatory)? '是' : '否' }}
           </template>
        </el-table-column>
        <el-table-column
                prop="dataType"
                align="center"
                label="数据类型"
        >
        <template slot-scope="scope">
            {{ getTextString(scope.row.dataType) }}
        </template>
        </el-table-column>
        <el-table-column
                prop="minLength"
                align="center"
                label="最小长度"
        >
        </el-table-column>
        <el-table-column
                prop="maxLength"
                align="center"
                label="最大长度"
        >
        </el-table-column>
         <el-table-column
                align="center"
                label="操作"
        >
        <template slot-scope="scope">
<!--          class="el-icon-top icon"-->
<!--          class="el-icon-bottom icon"-->
          <el-button type="text" v-if="scope.$index !==0"  @click="clickUp(scope.$index)" ><i style="font-size: 19px;" class="el-icon-upload2"></i></el-button>
          <el-button type="text" v-if="scope.$index !==tableData.length-1"  @click="clickDown(scope.$index)" ><i  style="font-size: 19px;" class="el-icon-download"></i></el-button>
          <el-button type="text" style="text-align: center;" @click="handleDel(scope.$index)"><i  style="font-size: 19px;" class="el-icon-delete"></i></el-button>
        </template>
        </el-table-column>
      </el-table>

      <div class="saveBox">
          <el-button type="primary" @click="saveAdd">保存</el-button>
      </div>
      <!--弹窗-->
      <el-dialog
        title="添加一行"
        :visible="dialogVisible"
        :modal-append-to-body="false"
        :before-close="handleClose"
        :close-on-click-modal="false"
        width="600px"
        >
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
            <el-form-item label="中文名称" prop="nameCh">
                <el-input v-model="ruleForm.nameCh"></el-input>
            </el-form-item>
            <el-form-item label="英文名称" prop="nameEn">
                <el-input v-model="ruleForm.nameEn"></el-input>
            </el-form-item>
            <el-form-item label="数据类型" prop="dataType">
                <el-select v-model="ruleForm.dataType">
                    <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="最小长度" prop="minLength">
                <el-input v-model="ruleForm.minLength"></el-input>
            </el-form-item>
            <el-form-item label="最大长度" prop="maxLength">
                <el-input v-model="ruleForm.maxLength"></el-input>
            </el-form-item>
            <el-form-item label="是否必填" prop="mandatory" @change="checkIsRequired">
                <el-select v-model="ruleForm.mandatory">
                    <el-option v-for="item in mandatoryList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <!-- <el-checkbox v-model="ruleForm.mandatory" @change="checkIsRequired"></el-checkbox> -->
            </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="dialogAdd">确 定</el-button>
        </span>
      </el-dialog>

  </div>
</template>
<script>
import {  fetchAddDataModel, fetchDataModelInfo } from '@/api/customPage.js';
export default {
    data() {
        var validatorLength = (rule, value, callback) => {
            if(rule.field!=='mandatory' && value === '') {
                callback(new Error('请输入数字'))
            }else {
                if(Boolean(this.ruleForm.mandatory) && this.ruleForm.minLength > 0) {
                    callback(new Error('设置了必填项，最小长度请设置为0'))
                }else {
                    if(this.ruleForm.maxLength && this.ruleForm.minLength && (this.ruleForm.maxLength - this.ruleForm.minLength <= 0)) {
                      callback(new Error('最小长度不能大于等于最大长度'))
                    }
                }

                console.log(this.ruleForm.mandatory)
                console.log(this.ruleForm.minLength)
                console.log(Boolean(this.ruleForm.mandatory))
                console.log(this.ruleForm.minLength > 0)
                callback()
            }
        }
        return {
          modelId: '',
          saveForm:{
            modelName: '',
          },
          saveRules: {
            modelName: [
              { required: true, message: '请输入产品型号', trigger: 'blur' },
            ],
          },
          dialogVisible: false,
          tableData: [
          ],
          ruleForm: {
            nameCh: '',
            nameEn: '',
            minLength: '',
            maxLength: '',
            mandatory: 0,
            dataType:  '',
          },
          mandatoryList: [
              {
                  label: '是',
                  value: 1,
              },
              {
                  label: '否',
                  value: 0,
              },
          ],
          rules: {
            nameCh: [
              { required: true, message: '请输入中文名称', trigger: 'blur' },
            ],
            nameEn: [
              { required: true, message: '请输入字母数字或下划线组成的英文名称，且每个元数据的英文名称不能重复', trigger: 'blur' },
              { pattern: /^[0-9a-zA-Z_]+$/, message: '请输入字母数字或下划线组成的英文名称，且每个元数据的英文名称不能重复', trigger: 'blur'}
            ],
            type: [
                { required: true, message: '请选择数据类型', trigger: 'change' },
            ],
            minLength: [
            //   { required: true, message: '请输入最小长度', trigger: 'blur' },
                { pattern: /^\+?[0-9]\d*$/, message: '请输入数字', trigger: 'blur'},
                {validator: validatorLength, trigger: 'blur'}
            ],
            maxLength: [
            //    { required: true, message: '请输入最大长度', trigger: 'blur' },
               { pattern: /^\+?[0-9]\d*$/, message: '请输入数字', trigger: 'blur'},
               {validator: validatorLength, trigger: 'blur'}
            ],
            mandatory: [
               { required: true, message: '请选择是否必填', trigger: 'change' },
            //    {validator: validatorLength, trigger: 'change'}
            ],
            dataType: [
               { required: true, message: '请选择数据类型', trigger: 'change' },
            ]
          },
          typeOptions: [
              {
                  value: 4,
                  label: '字符串'
              },
              {
                  value: 5,
                  label: '单选'
              },
              {
                  value: 6,
                  label: '日期'
              },
          ]
        };
    },
    created() {

    },
    mounted() {
      this.init()
    },
    methods: {
      clickUp(index) {
        if (index === 0) {
          this.$message('不能上移了')
          return
        }
        const arr = this.tableData
        arr.splice(index - 1, 1, ...arr.splice(index, 1, arr[index - 1]))
      },
      // 下移
      clickDown(index) {
        if (index === this.tableData.length - 1) {
          this.$message('不能下移了')
          return
        }
        const arr = this.tableData
        arr.splice(index, 1, ...arr.splice(index + 1, 1, arr[index]))
      },
        checkIsRequired(val) {
          this.$refs.ruleForm.validateField('minLength')
        },
        init() {
            this.modelId = this.$route.query.id
            if(this.modelId) {
              this.saveForm.modelName =  this.$route.query.modelName
              if (this.$route.query.modeType === 'copy'){
                this.saveForm.modelName = ""
              }
                let params = {
                    dataModelId: this.modelId,
                }
                fetchDataModelInfo(params).then(res => {
                    if(res.code === 200) {
                        this.tableData = res.data
                      if (this.$route.query.modeType === 'copy'){
                        this.tableData.id = null
                        this.tableData.modelName = ""
                      }
                    }
                })
            }

        },
        handleCurrentChange () {
            this.getTableList()
        },
        getTableList() {

        },
        handleAdd() {
            this.dialogVisible = true
        },
        handleClose() {
            this.$refs.ruleForm.resetFields()
            this.dialogVisible = false
        },
        dialogAdd() {
            this.$refs.ruleForm.validate((valid) => {
                if(valid) {
                    this.tableData.push({
                      nameCh: this.ruleForm.nameCh,
                      nameEn: this.ruleForm.nameEn,
                      minLength: this.ruleForm.minLength,
                      maxLength: this.ruleForm.maxLength,
                      mandatory:  this.ruleForm.mandatory,
                      dataType: this.ruleForm.dataType,
                    })
                   this.dialogVisible = false
                   this.$refs.ruleForm.resetFields()
                }
            })
        },
        handleDel(index) {
             this.tableData.splice(index, 1)
        },
        saveAdd() {
            // 添加或编辑
            this.$refs.saveForm.validate((valid) => {
                if(valid) {
                    console.log(this.$route.query.modeType)
                    let params={}
                    if(this.$route.query.modeType === 'add') { // 添加
                        params = {
                            companyId: JSON.parse(localStorage.getItem('info')).companyId,
                            modelName: this.saveForm.modelName,
                            dataModelDatas: JSON.stringify(this.tableData)
                        }
                    }else if (this.$route.query.modeType === 'copy'){
                      //复制
                      params = {
                        companyId: JSON.parse(localStorage.getItem('info')).companyId,
                        modelName: this.saveForm.modelName,
                        dataModelDatas: JSON.stringify(this.tableData)
                      }
                    } else { // 编辑
                        params = {
                            companyId: JSON.parse(localStorage.getItem('info')).companyId,
                            id: this.modelId,
                            modelName: this.saveForm.modelName,
                            dataModelDatas: JSON.stringify(this.tableData)
                        }
                    }
                    fetchAddDataModel(params).then(res => {
                        if(res.code === 200) {
                            this.$message({
								message: "保存成功",
								type: "success",
							});
                            this.$router.push({path: "/configHandleType"});
                        }else {
                           this.$message({
								message: res.message,
								type: "error",
							});
                        }
                    })
                }
            })
        },
        getTextString(dataType) {
            let str='字符串'
            str = this.typeOptions.filter(item => item.value === dataType) ? this.typeOptions.filter(item => item.value === dataType)[0].label : '字符串'
            return str
        }
    }
}
</script>
<style scoped lang="scss">
.page-container{
  background: #fff;
  padding: 10px;
}
.operations{
            //display: flex;
            justify-content: space-between;
            height: 60px;
            align-items: center;
            .operation-btns{
                //display: flex;
				    padding-top: 13px;
                div{
                    border-radius: 4px;
                    // background: #B9EBEA;
                    background: #51CDCB;
                    font-size: 14px;
                    line-height: 30px;
                    height: 30px;
                    color: #fff;
                    padding: 0 20px;
                    margin-right: 10px;
                    cursor: pointer;
					float:left;
                }
                .disabled{
                    background: #B9EBEA;
                }
            }
            .search-user{
                display: flex;
                .search-input{
                    height: 30px;
                    margin-right: 10px;
                    .el-input__inner{
                        height: 30px;
                    }
                }
                .search-btn{
                    border-radius: 4px;
                    // background: #B9EBEA;
                    background: #51CDCB;
                    font-size: 14px;
                    line-height: 30px;
                    height: 30px;
                    color: #fff;
                    padding: 0 20px;
                    cursor: pointer;
                }
                .disabled{
                    background: #B9EBEA;
                }
            }
        }

        .saveBox{
            text-align: center;
            padding-top: 20px;
            padding-bottom: 10px;
        }

</style>
